import React, { useState, useEffect } from "react";
import "./loading.css";
import shoessrzlogo from "../asset/Screen 2 - ShoeSZR Logo.png";

export default function Loading() {
  return (
    <>
      <div className="load-main">
        <div className="load-div">
          <img className="loadlogo" src={shoessrzlogo} alt="shoessrzlogo" />
          <p className="load-p">ShoeSZR</p>
        </div>
      </div>
      <div className="spinner-border text-dark spin-div" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </>
  );
}
