import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./signin.css";
import signlogo from "../../asset/Screen 7 - user icon.png";
import terlogo from "../../asset/Screen 15 - letter icon white.png";

export default function Signin() {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const navigate1 = useNavigate();
  const navigate2 = useNavigate();
  const [alert, setAlert] = useState(null);

  // Function to show alert messages
  const showAlert = (type, message) => {
    setAlert({ type, message });
  };

  // Function to close alert and toggle the popup
  const closeAlert = () => {
    if (!isChecked) {
      setIsPopupOpen(true);
      setAlert(null);
    } else {
      setIsPopupOpen(false);
    }
  };

  // Function to handle accept all terms checkbox click
  const Accepttermsclick = (event) => {
    setIsChecked(event.target.checked);
    setIsPopupOpen(false);
  };

  // Function to handle Terms and Conditions toggle the popup
  const Viewtermsclick = () => {
    if (!isPopupOpen) {
      setIsPopupOpen(true);
    } else if (!isChecked) {
      showAlert(
        "error",
        "Please accept the terms and conditions to continue using ShoeSZR."
      );
      return;
    } else {
      setIsPopupOpen(false);
    }
  };

  // Function to handle "Sign In As Guest" button click
  function handleclick1() {
    // Retrieve user agent information
    const userAgent = navigator.userAgent;

    // Check if the user is on an iPhone or iPad
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      // Extract iPhone OS version
      const iPhoneOSVersionMatch = userAgent.match(/iPhone OS (\S+)/);
      const iPhoneOSVersion = iPhoneOSVersionMatch
        ? iPhoneOSVersionMatch[1]
        : "Unknown";

      // Set guest and device information to localStorage
      localStorage.setItem("guest", JSON.stringify(true));
      localStorage.setItem("device id", JSON.stringify(iPhoneOSVersion));
      localStorage.removeItem("userData");
      // Navigate to shoeselect page
      navigate1("/shoeselect");
    } else if (/Android/i.test(userAgent)) {
      // Extract device identifier from Android user agent
      const deviceNameMatch = userAgent.match(/\((.*?)\)/); //
      if (deviceNameMatch && deviceNameMatch.length > 1) {
        const deviceName = deviceNameMatch[1];
        const parts = deviceName.split(";");
        const deviceIdentifier = parts[2].trim();

        // Set guest and device information to localStorage
        localStorage.setItem("guest", JSON.stringify(true));
        localStorage.setItem("device id", JSON.stringify(deviceIdentifier));
        localStorage.removeItem("userData");
        // Navigate to shoeselect page
        navigate1("/shoeselect");
      }
    } else {
      // If the device is not recognized as iPhone or Android, navigate to shoeselect page
      console.log("Device: Other");
      navigate1("/shoeselect");
    }

    return;
  }

  // Function to handle "Sign In With Password" button click
  function handleclick() {
    // Remove guest information and navigate to login page
    localStorage.removeItem("guest");
    navigate("/login");
  }

  // Function to handle "Sign Up" button click
  function signuphandle() {
    // Remove guest information
    localStorage.removeItem("guest");
    // Retrieve user agent information
    const userAgent = navigator.userAgent;

    // Check if the user is on an iPhone or iPad
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      const iPhoneOSVersionMatch = userAgent.match(/iPhone OS (\S+)/);
      const iPhoneOSVersion = iPhoneOSVersionMatch
        ? iPhoneOSVersionMatch[1]
        : "Unknown";
      // Set device information to localStorage and navigate to signup page
      localStorage.setItem("device id", JSON.stringify(iPhoneOSVersion));
      navigate2("/signup");
    } else if (/Android/i.test(userAgent)) {
      // Extract device identifier from Android user agent
      const deviceNameMatch = userAgent.match(/\((.*?)\)/); //
      if (deviceNameMatch && deviceNameMatch.length > 1) {
        const deviceName = deviceNameMatch[1];
        const parts = deviceName.split(";");
        const deviceIdentifier = parts[2].trim();
        // Set device information to localStorage and navigate to signup page
        localStorage.setItem("device id", JSON.stringify(deviceIdentifier));
        navigate2("/signup");
      }
    } else {
      console.log("Device: Other");
      // If the device is not recognized as iPhone or Android, navigate to signup page
      navigate2("/signup");
    }
  }

  return (
    <>
      <div className="sign-main">
        <div className="signin-container">
          <img className="signinlogo" src={signlogo} alt="runlogo" />
        </div>
        <div>
          <h1 className="signin-h1">Let's Get Started</h1>

          <button className="sign-btn" onClick={handleclick1}>
            Sign In As Guest
          </button>
          {!isChecked ? (
            <p className="signin-p">
              By signing in you agree to our Privacy Policy and Terms of Use{" "}
              <a href="#" className="signin-a" onClick={Viewtermsclick}>
                View Terms
              </a>
            </p>
          ) : (
            <div className="sin-div">
              <label className="center-label1">
                <input
                  type="checkbox"
                  checked={isChecked}
                  className="pop-check1"
                  id="navigateCheckbox"
                  onClick={Accepttermsclick}
                />
                Click to accept all terms.
              </label>
            </div>
          )}
          <div className="line-container">
            <div className="left-content">Or</div>
          </div>
          <button className="sign-btn" onClick={handleclick}>
            Sign In With Password
          </button>
          <p className="sign-lastp">
            Don't have an account?{" "}
            <a href="#" onClick={signuphandle} className="signup-a">
              {" "}
              Sign Up
            </a>
          </p>
        </div>
      </div>

      {/* Terms and conditions section */}
      <div>
        {isPopupOpen && (
          <div className="custom-signpopup">
            <div className="popup-signcontent1">
              <i
                className="fal fa-times signpop-i"
                onClick={Viewtermsclick}
              ></i>
              <div className="pop-logo">
                <img className="terlogo " src={terlogo} alt="img1" />
              </div>
              <div className="WordSection1">
                <p
                  className="MsoNormal"
                  align="center"
                  style={{ marginBottom: 0, textAlign: "center" }}
                >
                  <span style={{ fontSize: "20px", lineHeight: "107%" }}>
                    Universole Fit Terms and Conditions
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "16.0pt", lineHeight: "107%" }}>
                    &nbsp;
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    Terms and Conditions (&quot;Terms&quot;)
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "16.0pt", lineHeight: "107%" }}>
                    &nbsp;
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Our Terms and Conditions were last updated on June 21
                  <sup>st</sup>, 2022.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Please read these terms and conditions carefully before using
                  Our Service.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    Interpretation and Definitions
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "16.0pt", lineHeight: "107%" }}>
                    &nbsp;
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    Interpretation
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  The following definitions shall have the same meaning
                  regardless of whether they appear in singular or in plural.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>
                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    Definitions
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    &nbsp;
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  For the purposes of these Terms and Conditions:
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p
                  className="MsoListParagraphCxSpFirst"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  "Application" means the software program provided by the
                  Company downloaded or used on a web browser by You on any
                  electronic device, named <b>Universole Fit</b>
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  "Application Store" means the digital distribution service
                  operated and developed by Apple Inc.
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0 }}
                >
                  (Apple App Store) or Google Inc. (Google Play Store) in which
                  the Application has been downloaded.
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  ""Affiliate" means an entity that controls, is controlled by
                  or is under common control with a party, where
                  &quot;control&quot; means ownership of 50% or more of the
                  shares, equity interest or other securities entitled to vote
                  for election of directors or other managing authority.
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  "Account" means a unique account created for You to access our
                  Service or parts of our Service.
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  "Company" (referred to as either &quot;the Company&quot;,
                  &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this
                  Agreement) refers to Universole Fit.
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  "Country" refers to Canada.
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  "Content" refers to content such as text, images, or other
                  information that can be posted, uploaded, linked to or
                  otherwise made available by You, regardless of the form of
                  that content.
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  "Device" means any device that can access the Service such as
                  a computer, a cell phone or a
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0 }}
                >
                  digital tablet.
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  "Feedback" means feedback, innovations or suggestions sent by
                  You regarding the attributes,
                </p>

                <p
                  className="MsoListParagraphCxSpLast"
                  style={{ marginBottom: 0 }}
                >
                  performance or features of our Service.
                </p>
                <p
                  className="MsoListParagraphCxSpFirst"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  "Service" refers to the Website.
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  "Terms and Conditions" (also referred as &quot;Terms&quot;)
                  mean these Terms and Conditions that form the entire agreement
                  between You and the Company regarding the use of the Service.
                  This Terms and Conditions Agreement was generated by TermsFeed
                  Mobile App Terms and Conditions Generator.
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  "Third-party Social Media Service" means any services or
                  content (including data, information,
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0 }}
                >
                  products or services) provided by a third-party that may be
                  displayed, included or made available by the Service.
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  "Website" refers to Universole Fit, accessible from{" "}
                  <a href="http://www.universolefit.com">
                    www.universolefit.com
                  </a>
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  "Personal Data" means data about a living individual who can
                  be identified based on this data (or from this and other
                  information either in our possession or likely to come into
                  our possession)
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  "Usage Data" is data collected automatically, either generated
                  by the Service or from the Service infrastructure itself (for
                  example, the duration of a page visit){" "}
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  "You" means the individual accessing or using the Service, or
                  the company, or other legal entity on behalf of which such
                  individual is accessing or using the Service, as applicable.
                </p>

                <p
                  className="MsoListParagraphCxSpLast"
                  style={{ marginBottom: 0 }}
                >
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    Acknowledgment
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    &nbsp;
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  These are the Terms and Conditions governing the use of this
                  Service and the agreement that operates
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  between You and the Company. These Terms and Conditions set
                  out the rights and obligations of all users regarding the use
                  of the Service.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Your access to and use of the Service is conditioned on Your
                  acceptance of and compliance with these
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Terms and Conditions. These Terms and Conditions apply to all
                  visitors, users and others who access or
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  use the Service.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  By accessing or using the Service You agree to be bound by
                  these Terms and Conditions. If You disagree
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  with any part of these Terms and Conditions then You may not
                  access the Service.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  You represent that you are over the age of 18. The Company
                  does not permit those under 18 to use the
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Service.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Your access to and use of the Service is also conditioned on
                  Your acceptance of and compliance with the
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Privacy Policy of the Company. Our Privacy Policy describes
                  Our policies and procedures on the collection, use and
                  disclosure of Your personal information when You use the
                  Application or the Website and tells You about Your privacy
                  rights and how the law protects You. Please read Our Privacy
                  Policy carefully before using Our Service.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <b>User Accounts</b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <b>&nbsp;</b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  When You create an account with Us, You must provide Us
                  information that is accurate, complete, and
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  current at all times. Failure to do so constitutes a breach of
                  the Terms, which may result in immediate
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  termination of Your account on Our Service.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  You are responsible for safeguarding the password that You use
                  to access the Service and for any activities or actions under
                  Your password, whether Your password is with Our Service or a
                  Third-Party Social Media Service.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  You agree not to disclose Your password to any third party.
                  You must notify Us immediately upon becoming aware of any
                  breach of security or unauthorized use of Your account. You
                  may not use as a username the name of another person or entity
                  or that is not lawfully available for use, a name or trademark
                  that is subject to any rights of another person or entity
                  other than You without appropriate authorization, or a name
                  that is otherwise offensive, vulgar, or obscene.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    Privacy Policy
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <b>Personal Data </b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <b>&nbsp;</b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  During the usage of our Service you we may ask you to provide
                  us with certain personally identifiable information to contact
                  or identify you as outlined above in as Personal Data. This
                  includes but is not limited to
                </p>

                <p
                  className="MsoListParagraphCxSpFirst"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  Name{" "}
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  Email address
                </p>

                <p
                  className="MsoListParagraphCxSpLast"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  Data on physical characteristics, including body measurements
                  such as width, length and arch height, heel-to-toe length and
                  other relevant measurement of your feet.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Processing of Personal Data will be done on the Device to
                  extent were possible, however You understand that some
                  Personal Data may need to be transmitted via the secure
                  protocol to be used by Our Service. Any such Personal Data
                  will be stored for a short period (24 hours) and then removed
                  from external databases.{" "}
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <b>Usage Data</b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <b>&nbsp;</b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  We may also collect information that your browser sends
                  whenever you visit our Service or access the Service by or
                  through a mobile device.{" "}
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <br />
                  The usage data may include information relating to your IP
                  address, browser type and statistics relating to website/web
                  application visits and other diagnostic data. When visiting
                  through your mobile device the Usage Data might include the
                  type of mobile device you use, your mobile device unique ID,
                  the IP address of your mobile device, your mobile operating
                  system, and the type of mobile Internet browser you use,
                  unique device identifiers and other diagnostic data.{" "}
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <b>Personal Data Usage </b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  The use of Personal Data is integral to our Service to provide
                  You with a recommendation. Universole fit requires the use of
                  Your Personal Data to perform the following:
                </p>

                <p
                  className="MsoListParagraphCxSpFirst"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  Creating a personal account
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  Analysis of your physical characteristics
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  Finding shoes of your size
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  Testing and improving our systems and services
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  Technical support{" "}
                </p>

                <p
                  className="MsoListParagraphCxSpLast"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  Managing our business
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <b>Sharing your Personal Data</b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  When we are working with third-party services, we transmit
                  minimum personal data. Third parties make requests regarding
                  information about the type and size of your shoes to our
                  server and we answer them about the relevance of these pairs
                  of shoes for a particular person.{" "}
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Third partiers are allowed to receive only the result of
                  impersonal analysis of Personal Data. Moreover, data transfer
                  isn"t possible without signing an NDA.{" "}
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Our service may allow you to interact with other digital
                  products and services. If you use our Services in connection
                  with a third-party product or service, your privacy rights on
                  any third-party platform may be governed by the third party"s
                  policies in parallel with our Services Privacy Policy as
                  outlined here. This will mean that any information the third
                  party collects outside of our Products or Websites, but which
                  the third-party with us, will be subject to the third- party
                  privacy policy.{" "}
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    Copyright{" "}
                  </span>
                  <span style={{ fontSize: "16.0pt", lineHeight: "107%" }}>
                    Policy
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    &nbsp;
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <b>Intellectual Property Infringement</b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <b>&nbsp;</b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  We respect the intellectual property rights of others. It is
                  Our policy to respond to any claim that Content posted on the
                  Service infringes a copyright or other intellectual property
                  infringement of any person. If You are a copyright owner, or
                  authorized on behalf of one, and You believe that the
                  copyrighted work has been copied in a way that constitutes
                  copyright infringement that is taking place through the
                  Service, You must submit Your notice in writing to the
                  attention of us via email (
                  <a href="mailto:info@universolefit.com">
                    info@universolefit.com
                  </a>
                  ) and include in Your notice a detailed description of the
                  alleged infringement.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  You may be held accountable for damages (including costs and
                  attorneys' fees) for misrepresenting that
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  any Content is infringing Your copyright.{" "}
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <b>
                    DMCA Notice and DMCA Procedure for Copyright Infringement
                    Claims
                  </b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  You may submit a notification pursuant to The Notice and
                  Notice regime by providing our
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Copyright Agent with the following information in writing:
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p
                  className="MsoListParagraphCxSpFirst"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  An electronic or physical signature of the person authorized
                  to act on behalf of the owner of the copyright's interest.
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  A description of the copyrighted work that You claim has been
                  infringed, including the URL (i.e., webpage address) of the
                  location where the copyrighted work exists or a copy of the
                  copyrighted work.
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  Identification of the URL or other specific location on the
                  Service where the material that You claim is infringing is
                  located.
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  Your address, telephone number, and email address.
                </p>

                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  A statement by You that You have a good faith belief that the
                  disputed use is not authorized by the copyright owner, its
                  agent, or the law.
                </p>

                <p
                  className="MsoListParagraphCxSpLast"
                  style={{ marginBottom: 0, marginLeft: "10px" }}
                >
                  <span style={{ fontFamily: "Symbol" }}>
                    .
                    <span style={{ fontSize: "7.0pt Times New Roman" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  A statement by You, made under penalty of perjury, that the
                  above information in Your notice is accurate and that You are
                  the copyright owner or authorized to act on the copyright
                  owner's behalf.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  You can contact us via email (
                  <a href="mailto:info@universolefit.com">
                    info@universolefit.com
                  </a>
                  ). Upon receipt of a notification, the Company will take
                  whatever action, in its sole discretion, it deems appropriate,
                  including removal of the challenged content from the Service.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    Intellectual{" "}
                  </span>
                  <span style={{ fontSize: "16.0pt", lineHeight: "107%" }}>
                    Property
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "16.0pt", lineHeight: "107%" }}>
                    &nbsp;
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  The Service and its original content (excluding Content
                  provided by You or other users), features and
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  functionality are and will remain the exclusive property of
                  the Company and its licensors.{" "}
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  The Service is protected by copyright, trademark, and other
                  laws of both the Country and foreign countries.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Our trademarks and trade dress may not be used in connection
                  with any product or service without the prior written consent
                  of the Company.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    Your Feedback to Us
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "16.0pt", lineHeight: "107%" }}>
                    &nbsp;
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  You assign all rights, title and interest in any Feedback You
                  provide the Company. If for any reason such
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  assignment is ineffective, You agree to grant the Company a
                  non-exclusive, perpetual, irrevocable, royalty free, worldwide
                  right and license to use, reproduce, disclose, sub-license,
                  distribute, modify and exploit such Feedback without
                  restriction.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    Links to Other Websites
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Our Service may contain links to third-party web sites or
                  services that are not owned or controlled by the Company.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  The Company has no control over, and assumes no responsibility
                  for, the content, privacy policies, or
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  practices of any third party web sites or services. You
                  further acknowledge and agree that the Company
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  shall not be responsible or liable, directly or indirectly,
                  for any damage or loss caused or alleged to be
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  caused by or in connection with the use of or reliance on any
                  such content, goods or services available on or through any
                  such web sites or services.{" "}
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  We strongly advise You to read the terms and conditions and
                  privacy policies of any third-party web sites or services that
                  You visit.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    Termination
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  We may terminate or suspend Your Account immediately, without
                  prior notice or liability, for any reason
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  whatsoever, including without limitation if You breach these
                  Terms and Conditions.{" "}
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Upon termination, Your right to use the Service will cease
                  immediately. If You wish to terminate Your
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Account, You may simply discontinue using the Service.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    &nbsp;
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    &nbsp;
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    Limitation of Liability
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "16.0pt", lineHeight: "107%" }}>
                    &nbsp;
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Notwithstanding any damages that You might incur, the entire
                  liability of the Company and any of its
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  suppliers under any provision of this Terms and Your exclusive
                  remedy for all of the foregoing shall be
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  limited to the amount actually paid by You through the Service
                  or 100 USD if You haven't purchased
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  anything through the Service.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  To the maximum extent permitted by applicable law, in no event
                  shall the Company or its suppliers be liable for any special,
                  incidental, indirect, or consequential damages whatsoever
                  (including, but not limited to damages for loss of profits,
                  loss of data or other information, for business interruption,
                  for personal injury, loss of privacy arising out of or in any
                  way related to the use of or inability to use the Service,
                  third-party software and/or third-party hardware used with the
                  Service, or otherwise in connection with any provision of this
                  Terms), even if the Company or any supplier has been advised
                  of the possibility of such damages and even if the remedy
                  fails of its essential purpose.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Some provinces do not allow the exclusion of implied
                  warranties or limitation of liability for incidental or
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  consequential damages, which means that some of the above
                  limitations may not apply. In these provinces,each party's
                  liability will be limited to the greatest extent permitted by
                  law.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    &quot;AS IS&quot; and &quot;AS{" "}
                  </span>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    AVAILABLE
                  </span>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    &quot; Disclaimer
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  The Service is provided to You &quot;AS IS&quot; and &quot;AS
                  AVAILABLE&quot; and with all faults and defects without
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  warranty of any kind. To the maximum extent permitted under
                  applicable law, the Company, on its own
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  behalf and on behalf of its Affiliates and its and their
                  respective licensors and service providers, expressly
                  disclaims all warranties, whether express, implied, statutory
                  or otherwise, with respect to the Service,including all
                  implied warranties of merchantability, fitness for a
                  particular purpose, title and
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  non-infringement, and warranties that may arise out of course
                  of dealing, course of performance, usage or trade practice.
                  Without limitation to the foregoing, the Company provides no
                  warranty or undertaking, and makes no representation of any
                  kind that the Service will meet Your requirements, achieve any
                  intended results, be compatible or work with any other
                  software, applications, systems or services, operate without
                  interruption, meet any performance or reliability standards or
                  be error free or that any errors or defects can or will be
                  corrected.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Without limiting the foregoing, neither the Company nor any of
                  the company's provider makes any
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  representation or warranty of any kind, express or implied:
                  (i) as to the operation or availability of the
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Service, or the information, content, and materials or
                  products included thereon; (ii) that the Service will be
                  uninterrupted or error-free; (iii) as to the accuracy,
                  reliability, or currency of any information or content
                  provided through the Service; or (iv) that the Service, its
                  servers, the content, or e-mails sent from or on behalf of the
                  Company are free of viruses, scripts, trojan horses, worms,
                  malware, timebombs or other harmful components.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Some jurisdictions do not allow the exclusion of certain types
                  of warranties or limitations on applicable
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  statutory rights of a consumer, so some or all of the above
                  exclusions and limitations may not apply to You.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  But in such a case the exclusions and limitations set forth in
                  this section shall be applied to the greatest
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  extent enforceable under applicable law.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "16.0pt", lineHeight: "107%" }}>
                    Governing Law
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  The laws of the Country, excluding its conflicts of law rules,
                  shall govern this Terms and Your use of the
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Service. Your use of the Application may also be subject to
                  other local, state, national, or international laws.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    Disputes Resolution
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  If You have any concern or dispute about the Service, You
                  agree to first try to resolve the dispute informally by
                  contacting the Company.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    For European Union (EU) Users
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "16.0pt", lineHeight: "107%" }}>
                    &nbsp;
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  If You are a European Union consumer, you will benefit from
                  any mandatory provisions of the law of the
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  country in which you are resident in.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    United States Legal Compliance
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  You represent and warrant that (i) You are not located in a
                  country that is subject to the United States
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  government embargo, or that has been designated by the United
                  States government as a &quot;terrorist
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  supporting&quot; country, and (ii) You are not listed on any
                  United States government list of prohibited or
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  restricted parties.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    Severability and Waiver
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    Severability
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  If any provision of these Terms is held to be unenforceable or
                  invalid, such provision will be changed and
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  interpreted to accomplish the objectives of such provision to
                  the greatest extent possible under applicable law and the
                  remaining provisions will continue in full force and effect.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    Waiver
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  Except as provided herein, the failure to exercise a right or
                  to require performance of an obligation under these Terms
                  shall not effect a party's ability to exercise such right or
                  require such performance at any time thereafter nor shall the
                  waiver of a breach constitute a waiver of any subsequent
                  breach.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    Changes to These Terms and Conditions
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  We reserve the right, at Our sole discretion, to modify or
                  replace these Terms at any time. If a revision is
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  material We will make reasonable efforts to provide at least
                  30 days' notice prior to any new terms taking effect. What
                  constitutes a material change will be determined at Our sole
                  discretion.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  By continuing to access or use Our Service after those
                  revisions become effective, You agree to be bound by the
                  revised terms. If You do not agree to the new terms, in whole
                  or in part, please stop using the website and the Service.
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    Contact Us
                  </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &nbsp;
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  If you have any questions about these Terms and Conditions,
                  You can contact us:
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &#9679; By visiting this page on our website:
                  www.universolefit.com
                </p>

                <p className="MsoNormal" style={{ marginBottom: 0 }}>
                  &#9679; By sending us an email: info@universolefit.com
                </p>
              </div>
              <div>
                <label className="center-label">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    className="pop-check"
                    id="navigateCheckbox"
                    onClick={Accepttermsclick}
                  />
                  Click to accept all terms.
                </label>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Alert messages */}
      <div className="custom-App">
        {alert && (
          <div className={`custom-alert ${alert.type}`}>
            <div>{alert.message}</div>
            <hr className="custom-hr" />
            <button className="custom-btn" onClick={closeAlert}>
              OK
            </button>
          </div>
        )}
      </div>
    </>
  );
}
