import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Step1img from "../../asset/OverView_Image1.png";
import Step2img from "../../asset/OverView_Image2.png";
import Step3img from "../../asset/OverView_Image3.png";
import Step4img from "../../asset/OverView_Image4.png";
import "./instructions.css";
import { useAlert } from "react-alert";

const Instructions = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const navigate = useNavigate();
   const alert = useAlert();

  // Effect to handle slide transition and navigation
  useEffect(() => {
    function change(slide) {
      var previousSlide = localStorage.getItem("previouSlide");
      setActiveSlide(slides.indexOf(slide));
      // Check for specific slide transition and navigate accordingly
      if (slides.indexOf(slide) == 0 && previousSlide == 3) {
        navigate("/signin");
      }
      localStorage.setItem(
        "previouSlide",
        JSON.stringify(slides.indexOf(slide))
      );
    }
    // Event listeners for slide transition
    let slides = document.querySelectorAll(".carousel-item");
    slides = [...slides];
    slides.forEach((slide) =>
      slide.addEventListener("transitionstart", () => change(slide))
    );
  }, []);

  // Function to show an alert when the user tries to proceed without scanning
  function selectscan() {
    alert.show("Please scan product first");
  }

  // Function to handle the "Next" button click
  var nextSlide;
  const handleNextSlide = () => {

    // Determine the next slide
    nextSlide = (activeSlide + 1) % 4;

    // Check if it's a valid slide and update the state
    if (nextSlide <= 3 && activeSlide != 3) {
      setActiveSlide(nextSlide);
    } else {
      // Check conditions and navigate to the appropriate page
      const productName = localStorage.getItem("productName");
      const productId = localStorage.getItem("productId");
      const collectionId = localStorage.getItem("collectionId");

     
    var orderType = localStorage.getItem("orderType")
    if (orderType == 'catalogue' || orderType == 'home' || orderType == 'product' || orderType == 'collection') {
      navigate("/signin");
    }else{
        return selectscan();
      }
    }
  };

  return (
    <div className="instructions-main">
      <div
        id="carouselIndicators"
        className="carousel slide carousel-fade"
      >
        <div className="carousel-inner">
          <div className="page-div1">
            <div
              className={`carousel-item ${activeSlide === 0 ? "active" : ""}`}
            >
              <img className="Step1img" src={Step1img} alt="Step1img" />
              <h5 className="instructions-h5">You take scans to find your size</h5>
            </div>
            <div
              className={`carousel-item ${activeSlide === 1 ? "active" : ""}`}
            >
              <img className="Step2img" src={Step2img} alt="Step2img" />
              <h5 className="page2-h5">We take important shoe measurements</h5>
            </div>
            <div
              className={`carousel-item ${activeSlide === 2 ? "active" : ""}`}
            >
              <img className="Step3img" src={Step3img} alt="Step3img" />
              <h5 className="page3-h5">
                Then we test how you fit in each shoe
              </h5>
            </div>
            <div
              className={`carousel-item ${activeSlide === 3 ? "active" : ""}`}
            >
              <img className="Step4img" src={Step4img} alt="Step4img" />
              <h5 className="page4-h5">
                So you have the right shoe size when you shop
              </h5>
            </div>
          </div>
        </div>
        <div className="page-div2">
          <div className="instructions-slide ">
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselIndicators"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselIndicators"
                data-bs-slide-to="3"
                aria-label="Slide 3"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <button
        className="instructions-btn"
        type="button"
        onClick={handleNextSlide}
        data-bs-target="#carouselIndicators"
        data-bs-slide="next"
      >
        <span className="inst-span2">Next</span>
      </button>
    </div>
  );
};

export default Instructions;
