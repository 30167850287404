import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import axios from "axios";
import "./rightfoot.css";
import leftlogo1 from "../../asset/Vector (2).png";
import leftlogo3 from "../../asset/camera _icon.png";
import successlogo from "../../asset/ph_seal-check-bold.png";
import errorlogo from "../../asset/errorimg.png";
import Spinners from "../Page/Spinners";
import snake from "../../asset/output-onlinegiftools.gif";

export default function Rightfoot() {
  const [successpopup, setSuccesspopup] = useState(false);
  const [isnotperfectfootpopup, setIsnotperfectfootpopup] = useState(false);
  const [isPopuploading, setIsPopuploading] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [isClicked, setIsClicked] = useState(false);

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Calculate dynamic styling based on window dimensions
  const desiredHeightPercentage = 100;
  const height = (windowHeight * desiredHeightPercentage) / 100;
  const height1 = height;
  const percentageOfWindowHight = windowHeight * 0.4;
  const percentageOfWindowHight1 = windowHeight * 0.34;
  const percentageOfWindowHightCenterLine1 = windowHeight * 0.28;
  const percentageOfWindowHight3 = windowHeight * 0.68;

  const windowWidth = window.innerWidth;
  const desiredHeightPercentagew = 100;
  const width = (windowWidth * desiredHeightPercentagew) / 100;
  const width1 = width;
  const percentageOfWindowWidth = windowWidth * 0.3;

  // After styling for creating a line
  const afterStyle = {
    content: '""',
    position: "fixed",
    width: "0px",
    height: `${percentageOfWindowHightCenterLine1}px`,
    right: "0",
    left: "0",
    margin: "0 auto",
    border: "1px dashed green",
    zIndex: "999",
    top: `${percentageOfWindowHight}px`,
  };

  // Video constraints for the webcam
  const videoConstraints = {
    width: height1,
    height: width1,
    facingMode: "environment",
  };

  // Effect to handle capture when isClicked changes
  useEffect(() => {
    if (isClicked) {
      handleCapture();
    }
  }, [isClicked]);

  // Function to handle image capture
  const handleCapture = async () => {
    // Retrieve left image link from local storage
    const leftimg = localStorage.getItem("left_image");
    const left_link = JSON.parse(leftimg);
    setIsLoading(true);
    setIsClicked(true);

    // Capture the image from the webcam
    const imageSrc = webcamRef.current.getScreenshot();

    // Check if image capture failed
    if (!imageSrc) {
      alert("Right image capture failed.");
      setIsClicked(false);
      setIsLoading(false);
      return;
    } else {
      console.log("Captured Image right:", imageSrc);
    }

    setCapturedImage(imageSrc);

    localStorage.setItem("right_image", JSON.stringify(imageSrc));

    // Retrieve additional data from local storage
    const userDataString = localStorage.getItem("userData");
    const userId = JSON.parse(userDataString);
    const deviceid = JSON.parse(localStorage.getItem("device id"));
    const gueststatus = JSON.parse(localStorage.getItem("guest"));
    const papersize = JSON.parse(localStorage.getItem("size"));
    var input = papersize;
    var parts = input.split(" - ");
    var paper_size = parts[0];
    const gender = JSON.parse(localStorage.getItem("gender"));

    // API request to upload the captured images
    try {
      var body;
      if (gueststatus) {
        body = {
          left_link: left_link,
          right_link: imageSrc,
          device_id: deviceid,
          guest: gueststatus,
          paper_size: paper_size,
          sex: gender,
        };
      } else {
        body = {
          left_link: left_link,
          right_link: imageSrc, 
          userid: userId,
          guest: gueststatus,
          paper_size: paper_size,
          sex: gender,
        };
      }
      console.log(body)
      const response = await axios.post(
        process.env.REACT_APP_BASEURL+"/image/upload-images",
        body
      );
      if (response.data.status == true) {
        // If successful, update loading state and open a success popup
        setIsLoading(false);
        successtoggle();
      } else {
        // If not successful, alert the user with the API response message
        alert(response.data.message);
        return;
      }
    } catch (error) {
      // Catch any errors that occur during the API request and alert the user
      alert(error.message);
    }
  };

  // Function to handle navigation after successful capture
  const handleclick = async () => {
    const userDataString = localStorage.getItem("userData");
    const userId = JSON.parse(userDataString);
    const deviceid = JSON.parse(localStorage.getItem("device id"));
    const body = {
      userid: userId,
      device_id: deviceid,
    };
    try {
      // API request to get image link
      const response = await axios.post(
        process.env.REACT_APP_BASEURL+"/api/image-link",
        body
      );
        // Check if the response contains the expected data structure
      if (response.data && response.data.status && response.data.message) {
        toggleloading();
        setTimeout(() => {
          navigate("/footcomplete");
        }, 1000);
      } else {
        notperfectfoottoggle();
        alert(response.data.message);
        return;
      }
    } catch (error) {
      alert(error.message);
    }
  };

  // Toggle functions for handling not perfect foot scan popups
  const notperfectfoottoggle = () => {
    setIsnotperfectfootpopup(!isnotperfectfootpopup);
  };
  
   //handle to loading popup
  const toggleloading = () => {
    setIsPopuploading(!isPopuploading);
  };
  
 //handle to successfully popup
  const successtoggle = () => {
    setSuccesspopup(!successpopup);
  };

  return (
    <>
      {isLoading ? (
        <Spinners />
      ) : (
        <div className="right-main">
          <>
            <div
              className="rightfoot-top"
              style={{ height: `${percentageOfWindowHight}px` }}
            ></div>

            <div
              className="rightfoot-left"
              style={{
                width: `${percentageOfWindowWidth}px`,
                top: `${percentageOfWindowHight}px`,
              }}
            ></div>
            <div
              className="rightfoot-right"
              style={{
                width: `${percentageOfWindowWidth}px`,
                top: `${percentageOfWindowHight}px`,
              }}
            ></div>
            <div
              className="rightfoot-bottom"
              style={{
                height: `${percentageOfWindowHight1}px`,
                top: `${percentageOfWindowHight3}px`,
              }}
            ></div>
            <div className="right-pdiv">
              <h1 className="right-h1">Right Foot</h1>
              <p className="right-p1">
                1. Place your foot on the paper with your heel against the wall.
              </p>
              <p className="right-p2">
                2. To center your foot, place it on the green line.{" "}
              </p>
              <p className="right-p3">
                3. Make sure the paper is straight and within the box.{" "}
              </p>
              <p className="right-p4">4. Take the photo!</p>
            </div>

            <div className="right-imgdiv">
              <div className="right-imgdiv1">
                <img className="right-img1" src={leftlogo1} alt="leftlogo1" />
                <div className="webcam-div">
                  <Webcam
                    className={isClicked ? "clicked-webcamr" : "right-img2web"}
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    screenshotQuality={0.5}
                    onClick={() => setIsClicked(true)}
                  />
                </div>
                <div style={afterStyle}></div>
              </div>
            </div>
          </>
          <img
            className="right-img3"
            src={leftlogo3}
            alt="leftlogo3"
            onClick={() => setIsClicked(true)}
          />
        </div>
      )}
      {successpopup && (
        <div className="right-popup">
          <div className="right-content">
            <div className="rightpop-main">
              <img
                className="rightsuccesslogo"
                src={successlogo}
                alt="successlogo"
              />
              <div className="rightpop-div">
                <h1 className="rightpop-h1">Congratulations</h1>
              </div>
              <p className="rightpop-p">
                Your right foot scan was captured successfully!
              </p>
              <button className="rightpop-btn" onClick={handleclick}>
                Continue
              </button>
            </div>
          </div>
        </div>
      )}

      {isnotperfectfootpopup && (
        <div className="error-popup">
          <div className="errorpop-content1">
            <div className="errorpop-logo">
              <img className="errorlogo " src={errorlogo} alt="errorlogo" />
            </div>
            <h1 className="error-h1">Your Foot Scan Were</h1>
            <h1 className="error1-h1"> Not Perfect</h1>
            <p className="error-p">
              Please move to an area with better lighting, not near a door frame
              or an angled wall
            </p>
            <p className="error-p">
              Make sure at least 3 corners of the paper are visible.
            </p>
            <p className="error-p">
              {" "}
              And that your foot is straight when you redo your foot scans.
            </p>
            <button className="errorpop-btn">Redo Foot Scan</button>
          </div>
        </div>
      )}

      {isPopuploading && (
        <div className="success-popup">
          <div className="successpop-content1">
            <img
              className="rightsuccesspop"
              src={successlogo}
              alt="successlogo"
            />
            <h1 className="success-h1">Your foot scans were</h1>
            <h1 className="success1-h1">captured</h1>
            <p className="success-p">
              Please hold tight as we finding your true size in each shoe
            </p>
            <img src={snake} className="success-loader" alt="navlogo" />
          </div>
        </div>
      )}
    </>
  );
}
