import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./logo.css";
import logo from "../../asset/MAin_Logo.png";

export default function Logopage() {
  const [imageWidth, setImageWidth] = useState("300px");
  const [imageHeight, setImageHeight] = useState("600px");
  const [borderRadius, setBorderRadius] = useState("0%");
  const [addClass, SetAddClass] = useState(true);
  const [objectfit, setObjectfit] = useState("");
  const [background, setBackground] = useState("");
  const [imageStyle, setImageStyle] = useState({
    width: imageWidth,
    height: imageHeight,
    borderRadius: borderRadius,
  });
  const navigate = useNavigate();

  // useEffect to animate and navigate to the next page
  useEffect(() => {
    const timer = setTimeout(() => {
      // Update state to trigger animation and change image style
      SetAddClass(false);
      setImageWidth("100px");
      setImageHeight("100px");
      setBorderRadius("50%");
      setObjectfit("contain");
      setBackground("black");

      // Navigate to the "/splashpage" route after a delay
      setTimeout(() => {
        navigate("/splashpage");
      }, 1000);
    }, 1000);

    // Cleanup function to clear the timer
    return () => clearTimeout(timer);
  }, [navigate]);

  // useEffect to update the image style when state variables change
  useEffect(() => {
    const newImageStyle = {
      width: imageWidth,
      height: imageHeight,
      borderRadius: borderRadius,
      objectFit: objectfit,
      background: background,
    };

    // Update the imageStyle state
    setImageStyle(newImageStyle);
  }, [imageWidth, imageHeight, borderRadius]);

  return (
    <div className={addClass ? "second-main addOnClass" : "second-main"}>
      <div className="second-div1">
        <img
          src={logo}
          alt="secondlogo"
          className="second-logo"
          style={imageStyle}
        />
      </div>
    </div>
  );
}
