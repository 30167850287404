import React from "react";
import { useNavigate, Link } from "react-router-dom";
import "./scaninstr.css";
import Socksimage from "../../asset/new folder/socks.png";
import Paperselectionimage from "../../asset/new folder/file (1).png";
import Paperplacementimage from "../../asset/new folder/mansory.png";
import Footpositionimage from "../../asset/new folder/foot.png";
import Camerapositionimage from "../../asset/new folder/phone-camera.png";
import Paperpositionsimage from "../../asset/new folder/white -focus.png";
import CameraImage from "../../asset/new folder/camera.png";

export default function Scaninstructions() {
  const navigate = useNavigate();
  const navigate1 = useNavigate();

  // Function to navigate to the "/startscanning" route
  function continuescanhandle() {
    navigate1("/startscanning");
  }

  // Function to navigate to the "/startscan" route
  function handlebackclick() {
    navigate("/startscan");
  }
  return (
    <div className="scaninst-main">
      <div className="scanbackground_img_sec">
        <i className="fas fa-arrow-left shoesel-i" onClick={handlebackclick}></i>
        <h1 className="scaninst-h1">Foot Scan Instructions</h1>
      </div>

      <div className="scaninst-textmain">
        <div className="scaninst-fdiv1">
          <div className="scaninst-box1">
            <img className="scaninst-logo1" src={Socksimage} alt="Socksimage" />
          </div>
          <div className="scaninst-fsdiv">
            <h1 className="scaninst-fdiv2h1">Socks</h1>
            <p className="scaninst-fdiv2p">Put on a pair of socks.</p>
          </div>
        </div>

        <div className="scaninst-fdiv2">
          <div className="scaninst-box2">
            <img className="scaninst-logo2" src={Paperselectionimage} alt="Paperselectionimage" />
          </div>
          <div className="scaninst-fsdiv">
            <h1 className="scaninst-fdiv2h1">Paper Selection</h1>
            <p className="scaninst-fdiv2p">
              Get a piece of paper. For foot sizes > 12 you must use legal
              paper.
            </p>
          </div>
        </div>

        <div className="scaninst-fdiv3">
          <div className="scaninst-box3">
            <img className="scaninst-logo3" src={Paperplacementimage} alt="Paperplacementimage" />
          </div>
          <div className="scaninst-fsdiv">
            <h1 className="scaninst-fdiv2h1">Paper Placement</h1>
            <p className="scaninst-fdiv2p">
              Place the paper on the ground with the short edge against the
              wall.
            </p>
          </div>
        </div>

        <div className="scaninst-fdiv4">
          <div className="scaninst-box4">
            <img className="scaninst-logo4" src={Footpositionimage} alt="Footpositionimage" />
          </div>

          <div className="scaninst-fsdiv">
            <h1 className="scaninst-fdiv2h1">Foot Position </h1>
            <p className="scaninst-fdiv2p">
              Stand on the paper and put your heel against the wall.
            </p>
          </div>
        </div>

        <div className="scaninst-fdiv5">
          <div className="scaninst-box5">
            <img className="scaninst-logo5" src={Camerapositionimage} alt="Camerapositionimage" />
          </div>

          <div className="scaninst-fsdiv">
            <h1 className="scaninst-fdiv2h1">Camera Position</h1>
            <p className="scaninst-fdiv2p">
              Hold your phone level to the ground, at hip height, with the
              camera facing down.
            </p>
          </div>
        </div>

        <div className="scaninst-fdiv6">
          <div className="scaninst-box6">
            <img className="scaninst-logo6" src={Paperpositionsimage} alt="Paperpositionsimage" />
          </div>

          <div className="scaninst-fsdiv">
            <h1 className="scaninst-fdiv2h1">Paper Positions</h1>
            <p className="scaninst-fdiv2p">
              Keep the paper straight and with in the box
            </p>
          </div>
        </div>

        <div className="scaninst-fdiv7">
          <div className="scaninst-box7">
            <img className="scaninst-logo7" src={CameraImage} alt="CameraImage" />
          </div>

          <div className="scaninst-fsdiv">
            <h1 className="scaninst-fdiv2h1">Photo</h1>
            <p className="scaninst-fdiv2p">Take the photo!</p>
          </div>
        </div>
      </div>
      <div className="scan-lastdiv">
        <button className="scaninst-btn" onClick={continuescanhandle}>
          Continue To Scan
        </button>
        <p className="scaninst-lastp">
          I need more details.{" "}
          <Link className="scaninst-lasta" to="/footvideo">
            View Demo
          </Link>
        </p>
      </div>
    </div>
  );
}
