import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import axios from "axios";
import "./leftfoot.css";
import leftlogo1 from "../../asset/Vector (2).png";
import leftlogo3 from "../../asset/camera _icon.png";
import successlogo from "../../asset/ph_seal-check-bold.png";
import Spinners from "../Page/Spinners";
import "react-toastify/dist/ReactToastify.css";

export default function Leftfoot() {
  const [successpopup, setSuccesspopup] = useState(false);

  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  // Event listener for window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Calculate dimensions based on window size
  // ... (height, width calculations)
  const desiredHeightPercentage = 100;
  const height = (windowHeight * desiredHeightPercentage) / 100;
  const height1 = height;
  const percentageOfWindowHight = windowHeight * 0.4;
  const percentageOfWindowHight1 = windowHeight * 0.34;
  const percentageOfWindowHightCenterLine1 = windowHeight * 0.28;
  const percentageOfWindowHight2 = windowHeight * 0.32;
  const percentageOfWindowHight3 = windowHeight * 0.68;

  // After styling for creating a line
  const afterStyle = {
    content: '""',
    position: "fixed",
    width: "0px",
    height: `${percentageOfWindowHightCenterLine1}px`,
    right: "0",
    left: "0",
    margin: "0 auto",
    border: "1px dashed green",
    zIndex: "999",
    top: `${percentageOfWindowHight}px`,
  };

  const windowWidth = window.innerWidth;
  const desiredHeightPercentagew = 100;
  const width = (windowWidth * desiredHeightPercentagew) / 100;
  const width1 = width;
  const percentageOfWindowWidth = windowWidth * 0.3;
  const percentageOfWindowWidth1 = windowWidth * 0.25;
  const percentageOfWindowWidth2 = windowWidth * 0.48;


  // Video constraints for the webcam
  const videoConstraints = {
    width: height1,
    height: width1,
    facingMode: "environment",
  };

  // Capture image when isClicked changes
  useEffect(() => {
    if (isClicked) {
      handleCapture();
    }
  }, [isClicked]);

  //Function to handle Image capture logic
  const handleCapture = async () => {
    setIsLoading(true);
    setIsClicked(true);
    const imageSrc = webcamRef.current.getScreenshot();

    // if (imageSrc) {
    //   // Convert base64 to Blob
    //   const byteCharacters = atob(imageSrc.split(',')[1]);
    //   const byteNumbers = new Array(byteCharacters.length);

    //   for (let i = 0; i < byteCharacters.length; i++) {
    //     byteNumbers[i] = byteCharacters.charCodeAt(i);
    //   }

    //   const byteArray = new Uint8Array(byteNumbers);
    //   const blob = new Blob([byteArray], { type: 'image/jpeg' });

    //   // Create a Blob URL
    //   const blobUrl = URL.createObjectURL(blob);

    // } else {
    //   console.error('Error capturing photo');
    // }

    // Check if image capture failed
    if (!imageSrc) {
      alert("Left image capture failed.");
      setIsClicked(false);
      setIsLoading(false);
      return;
    } else {
      // Save captured image in local storage
      setCapturedImage(imageSrc);
      successtoggle();
    }
    localStorage.setItem("left_image", JSON.stringify(imageSrc));
  };

  const navigate = useNavigate();
  //Function to handle continue click and Navigation
  const Continuehandleclick = (capturedImage) => {
    navigate("/rightfoot");
  };

  //handle to successfully popup
  const successtoggle = () => {
    setSuccesspopup(!successpopup);
  };

  return (
    <>
      {isLoading ? (
        <Spinners />
      ) : (
        <div className="left-main">
          <>
            <div
              className="leftfoot-top"
              style={{ height: `${percentageOfWindowHight}px` }}
            ></div>
            <div
              className="leftfoot-left"
              style={{
                width: `${percentageOfWindowWidth}px`,
                top: `${percentageOfWindowHight}px`,
              }}
            ></div>
            <div
              className="leftfoot-right"
              style={{
                width: `${percentageOfWindowWidth}px`,
                top: `${percentageOfWindowHight}px`,
              }}
            ></div>
            <div
              className="leftfoot-bottom"
              style={{
                height: `${percentageOfWindowHight1}px`,
                top: `${percentageOfWindowHight3}px`,
              }}
            ></div>

            <div className="left-pdiv">
              <h1 className="left-h1">Left Foot</h1>
              <p className="left-p1">
                1. Place your foot on the paper with your heel against the wall.
              </p>
              <p className="left-p2">
                2. To center your foot, place it on the green line.{" "}
              </p>
              <p className="left-p3">
                3. Make sure the paper is straight and within the box.{" "}
              </p>
              <p className="left-p4">4. Take the photo!</p>
            </div>

            <div className="left-imgdiv">
              <div className="left-imgdiv1">
                <img
                  className="left-img1"
                  style={{
                    height: `${percentageOfWindowHight2}px`,
                    width: `${percentageOfWindowWidth2}px`,
                  }}
                  src={leftlogo1}
                  alt="leftlogo1"
                />

                <div className="webcam-div1">
                  <Webcam
                    className={isClicked ? "clicked-webcam" : "left-img2web"}
                    audio={false}
                    ref={webcamRef}
                    height={height}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    screenshotQuality={0.5}
                    onClick={() => setIsClicked(true)}
                  />
                </div>
                <div style={afterStyle}></div>
              </div>
            </div>
          </>

          <img
            className="left-img3"
            src={leftlogo3}
            alt="leftlogo3"
            onClick={() => setIsClicked(true)}
          />
        </div>
      )}

      {successpopup && (
        <div className="left-popup">
          <div className="left-content1">
            <div className="leftpop-main">
              <img
                className="successlogo"
                src={successlogo}
                alt="successlogo"
              />
              <div className="leftpop-div">
                <h1 className="leftpop-h1">Congratulations</h1>
              </div>
              <p className="leftpop-p">
                Your left foot scan was captured successfully!
              </p>
              <button className="leftpop-btn" onClick={Continuehandleclick}>
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
