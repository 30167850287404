import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loading from './Loading';
import Welcome from './Welcome';
import Instructions from './Page/Instructions';
import Signin from './Auth/Signin';
import Signup from './Auth/Signup';
import Login from './Auth/Login';
import Forgotpassword from './Auth/Forgotpassword';
import Resetpassword from './Auth/Resetpassword';
import Shoeselect from './Page/Shoeselect';
import Startscan from './Page/Startscan';
import Scaninstructions from './Page/Scaninstructions';
import Startscanning from './Page/Startscanning';
import Leftfoot from './Page/Leftfoot';
import Rightfoot from './Page/Rightfoot';
import Footcomplete from './Page/Footcomplete';
import Footvideo from './Page/Footvideo';
import Splashpage from './Page/Splash';
import Logopage from './Page/Logo';
import Spinners from './Page/Spinners';
import Shoeshopping from './Page/Shoeshopping';
import Start from './Start/Start';
import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";
import myLogo from "../asset/Screen 3 - ShoeSZR Logotran.png";

const App = () => {

  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  const handleClick = () => {
    pwaInstall({
      title: "Install Web App",
      logo: myLogo,
      features: (
        <ul>
          <li>Install App</li>
        </ul>
      ),
      description: "This is a very good app that does a lot of useful stuff. ",
    })
      .then(() => alert("App installed successfully or instructions for install shown"))
      .catch(() => alert("User opted out from installing"));
  };

  return (
    <BrowserRouter>
    <Routes>
  
    <Route path="/loading" element={<Loading />} />
    <Route path="/welcome" element={<Welcome />} />
    <Route path="/instructions" element={<Instructions />} />
    <Route path="/signin" element={<Signin />} />
    <Route path="/signup" element={<Signup />} />
    <Route path="/login" element={<Login />} />
    <Route path="/forgotpassword" element={<Forgotpassword />} />
    <Route path="/resetpassword" element={<Resetpassword />} />
    <Route path="/shoeselect" element={<Shoeselect />} />
    <Route path="/startscan" element={<Startscan />} />
    <Route path="/scaninstructions" element={<Scaninstructions />} />
    <Route path="/startscanning" element={<Startscanning />} />
    <Route path="/leftfoot" element={<Leftfoot />} />
    <Route path="/rightfoot" element={<Rightfoot />} />
    <Route path="/footcomplete" element={<Footcomplete />} />
    <Route path="/footvideo" element={<Footvideo />} />
    <Route path="/splashpage" element={<Splashpage />} />
    <Route path="/" element={<Logopage />} />
    <Route path="/spinners" element={<Spinners />} />
    {/*  */}
    <Route path="/shoeshopping" element={<Shoeshopping />} />
    <Route path="/scanmobile" element={<Start />} />
    {/*  */}
  </Routes>
    </BrowserRouter>
  );
};

export default App;