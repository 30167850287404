import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./resetpassword.css";
import forgotlogo from "../../asset/majesticons_user-box-line (2).png";
import passwordlogo from "../../asset/solar_password-outline.png";
import rightlogo from "../../asset/ph_seal-check-bold.png";
import snake from "../../asset/output-onlinegiftools.gif";

export default function Resetpassword() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();
  const [sendpassword, setSendpassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [cusalert, setAlert] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Function to show alert messages
  const showAlert = (type, message) => {
    setAlert({ type, message });
  };

  // Function to close alert and toggle the popup
  const closeAlert1 = () => {
    setAlert(null);
    setIsPopupOpen(!isPopupOpen);
  };

  // Function to handle the "Log In" button click
  function Backbuttonclick() {
    navigate("/login");
  }

  // Function to handle Reset Password button
  const Resetpasswordclick = () => {
    const data = { sendpassword, password, confirmpassword };

    // Validation checks
    if (!sendpassword || !password || !confirmpassword) {
      showAlert("error", "Please fill all fields");
      return;
    } else if (password !== confirmpassword) {
      showAlert("error", "Password and confirm password values are not equal.");
      return;
    } else if (password.length < 6) {
      showAlert("error", "Password should be at least 6 characters long.");
      return;
    }
    setIsLoading(true);
    // API call to reset the password
    var body = {
      otp: sendpassword,
      new_password: password,
    };
    axios
      .post(process.env.REACT_APP_BASEURL+"/api/reset-password", body)
      .then((response) => {
        if (response.data && response.data.message) {
          if (response.data.status === true) {
            // Show success alert and stop loading
            showAlert("success", response.data.message);
            setIsLoading(false);
          } else {
            // Show error alert and stop loading
            showAlert("error", response.data.message);
            setIsLoading(false);
            return;
          }
        } else {
          // Show error alert for invalid response
          showAlert("error", "Invalid response received from the server");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        // Show error alert and stop loading
        showAlert("error", error.message);
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="resetmain-section">
        <div className="resetbackground_img_sec">
          <h1 className="respass-h1">Password Reset</h1>
          <img className="resetlogo" src={forgotlogo} alt="forgotlogo" />
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="resetform_section">
              <div className="respass-div">
                <div className="input-group mb-3">
                  <span className="input-group-text " id="basic-addon1">
                    <i className="far fa-hashtag"></i>
                  </span>
                  <input
                    type="password"
                    className="form-control respass-input1"
                    placeholder="6 digit code sent to email"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={sendpassword}
                    onChange={(e) => setSendpassword(e.target.value)}
                  />
                </div>

                <div className="input-group mb-3">
                  <span className="input-group-text " id="basic-addon1">
                    {" "}
                    <img className="" src={passwordlogo} alt="passwordlogo" />
                  </span>
                  <input
                    type="password"
                    className="form-control respass-input1"
                    placeholder="Password"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div className="input-group mb-3">
                  <span className="input-group-text " id="basic-addon1">
                    {" "}
                    <img className="" src={passwordlogo} alt="passwordlogo" />
                  </span>
                  <input
                    type="password"
                    className="form-control respass-input1"
                    placeholder="Confirm Password"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={confirmpassword}
                    onChange={(e) => setConfirmpassword(e.target.value)}
                  />
                </div>

                <button className="respass-btn" onClick={Resetpasswordclick}>
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Popup for successful password reset */}
        {isPopupOpen && (
          <div className="resetcustom-popup">
            <div className="resetpopup-content">
              <div className="respop-main">
                <img className="rightlogo" src={rightlogo} alt="rightlogo" />
                <div className="pop-div">
                  <h1 className="pop-h1">You Successfully </h1>
                  <h1 className="pop1-h1">Reset Your Password</h1>
                </div>
                <p className="pop-p">
                  You will receive an email confirmation of your password reset
                </p>
                <button className="pop-btn" onClick={Backbuttonclick}>
                  Log In
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Loading spinner */}
      {isLoading && (
        <div className="custom-alert1">
          <img src={snake} className="snakeloader" alt="navlogo" />
        </div>
      )}
      {/* Alert messages */}
      <div className="custom-App">
        {cusalert && cusalert.message && (
          <div className={`custom-alert ${cusalert.type}`}>
            <div>{cusalert.message}</div>
            <hr className="custom-hr" />
            <button className="custom-btn" onClick={closeAlert1}>
              OK
            </button>
          </div>
        )}
      </div>
    </>
  );
}
