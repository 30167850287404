import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./footvideo.css";
import videoUrl from "../../asset/Screen 13 - Instructions Video.mp4";

export default function Footvideo() {
  const navigate = useNavigate();
  const [videoProgress, setVideoProgress] = useState(0);
  const playerRef = useRef(null);
  const navigate1 = useNavigate();

  // Handle the progress of the video during playback
  const handleVideoProgress = () => {
    const playedSeconds = playerRef.current.currentTime;

    // Check if the played seconds are less than or equal to 54
    if (playedSeconds <= 54 && playedSeconds !== 54) {
      // Update the video progress state
      setVideoProgress(playedSeconds);
    } else {
      // If the video progresses beyond 54 seconds, navigate to startscanning
      navigate1("/startscanning");
    }
  };

  // Handle the end of the video playback
  const handleVideoEnd = () => {
    // Navigate to '/startscanning' when the video ends
    navigate1("/startscanning");
  };

  // Handle click event to navigate to '/startscan'
  function backbuttonclick() {
    navigate("/startscan");
  }

  return (
    <div className="footvideo-main">
      <i className="fas fa-arrow-left shoesel-i" onClick={backbuttonclick}></i>
      <h1 className="footvideo-h1">Foot Scan Instructions</h1>

      <input
        type="range"
        min={0}
        max={playerRef.current ? playerRef.current.duration : 0}
        value={videoProgress}
        className="footvideo-input"
        onChange={(e) => {
          const newProgress = parseFloat(e.target.value);
          setVideoProgress(newProgress);
          if (playerRef.current) {
            playerRef.current.currentTime = newProgress;
          }
        }}
      />

      <div className="video-container">
        <video
          className="footvideo-video"
          ref={playerRef}
          controls
          width="100%"
          height="100%"
          autoPlay
          onTimeUpdate={handleVideoProgress}
          onEnded={handleVideoEnd}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}
