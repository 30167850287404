import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./splash.css";
import firstlogo from "../../asset/Screen 3 - ShoeSZR Logotran.png";
import snake from "../../asset/output-onlinegiftools.gif";
export default function Splashpage() {
  const navigate = useNavigate();

  // UseEffect to navigate to the welcome page after a delay
  useEffect(() => {
    const delayInMilliseconds = 3000;

    // Set a timeout to navigate after the specified delay
    const timeoutId = setTimeout(() => {
      navigate("/welcome");
    }, delayInMilliseconds);

    // Cleanup function to clear the timeout when the component unmounts
    return () => clearTimeout(timeoutId);
  }, [navigate]);

  return (
    <div className="first-cover">
      <div className="first-main">
        <div className="first-div1">
          <img src={firstlogo} alt="firstlogo" className="first-logo" />
          <h1 className="first-h1">Shoe-SZR</h1>
        </div>
      </div>
      <img src={snake} className="snakeloader" alt="navlogo" />
    </div>
  );
}
