import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./footcomplete.css";
import successlogo from "../../asset/ph_seal-check-bold.png";
import pop1logo from "../../asset/Logo_U.png";
import pop2logo from "../../asset/Icon.png";
import Spinners from "../Page/Spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAlert } from "react-alert";

export default function Footcomplete() {
  const [shoponcomputerpopup, setShoponcomputerpopup] = useState(false);
  const [collections, setCollections] = useState();
  const [email, setEmail] = useState("");
  const [shoesize, setShoesize] = useState("");
  const [allsizes, setallsizes] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [collectiondata, setCollectiondata] = useState([]);
  const alert = useAlert();

  // Log stored URL
  const url = localStorage.getItem("scanurl");

  // Fetch collection data on component mount
  useEffect(() => {
    getCollection();
  }, []);

  // Function to handle a scan selection
  function selectscan() {
    alert.show("Please scan product first");
  }

  // Function to get collection data
  async function getCollection() {
    // Check if URL is available
    console.log(url);

    if (!url) {
      selectscan();
    }
    // Split URL to get collection details
    const urlParts = url.split("/");
    if (urlParts[3] == "") {
      setCollections("index");
    } else {
      setCollections(urlParts[3]);
    }
  }
  const home = localStorage.getItem("home");

  // Function to Shop On Computer toggle popup
  const shoponcomputertoggle = () => {
    setShoponcomputerpopup(!shoponcomputerpopup);
  };

  // Function to submit shop on mobile
  const mobilehandlebutton = async () => {
    typecheckhandle("mobile");
  };

  // Function to submit shop on computer
  const computerhandlebutton = async () => {
    typecheckhandle("computer");
  };

  // Function to handle shop on mobile and computer type check
  const typecheckhandle = async (sendtype) => {
    // Retrieve data from local storage
    const storename = localStorage.getItem("storename");
    const productId = localStorage.getItem("productId");
    const productName = localStorage.getItem("productName");

    const collectionId = localStorage.getItem("collectionId");

    // Check if productId or collectionId is undefined or empty
    var orderType = localStorage.getItem("orderType");
    if (
      orderType == "catalogue" ||
      orderType == "home" ||
      orderType == "product" ||
      orderType == "collection"
    ) {
    } else {
      return selectscan();
    }

    setIsLoading1(true);
    // Retrieve additional data from local storage
    const selectedBrands = localStorage.getItem("selectedBrands");
    const rightimg = localStorage.getItem("right_image");
    const right_link = JSON.parse(rightimg);

    const leftimg = localStorage.getItem("left_image");
    const left_link = JSON.parse(leftimg);

    const gender = localStorage.getItem("gender");
    const sex = gender.substring(0, gender.length - 2);

    const paperdata = localStorage.getItem("size");
    const papersize = JSON.parse(paperdata);

    var input = papersize;
    var parts = input.split(" - ");
    var paper_size = parts[0];

    const userDataString = localStorage.getItem("userData");
    const userId = JSON.parse(userDataString);

    const signguestdata = localStorage.getItem("sign guest");
    const signguest = JSON.parse(signguestdata);

    const deviceid = JSON.parse(localStorage.getItem("device id"));

    const gueststatus = JSON.parse(localStorage.getItem("guest"));

    try {
      var body;
      // Create request body based on guest status
      if (gueststatus) {
        body = {
          device_id: deviceid,
          paper_size: paper_size,
          sex: sex,
          left_link: left_link,
          right_link: right_link,
          guest: gueststatus,
        };
      } else {
        body = {
          userid: userId,
          paper_size: paper_size,
          sex: sex,
          left_link: left_link,
          right_link: right_link,
          guest: gueststatus,
        };
      }

      // API call to upload images
      const response = await axios
        .post(process.env.REACT_APP_BASEURL + "/image/upload-images", body)
        .then(async (response) => {
          setIsLoading1(false);
          var sizeshoe = [];
          // Check if response contains shoe model recommendations
          if (
            response &&
            response.data &&
            response.data.status === true &&
            response.data.data &&
            response.data.data["Shoe Model Recommendations"]
          ) {
            var rideValue = "";

            // Iterate through shoe model recommendations
            for (var key in response.data.data["Shoe Model Recommendations"]) {
              if (
                response.data.data["Shoe Model Recommendations"].hasOwnProperty(
                  key
                )
              ) {
                var sp = key.split("_");
                var keyna = "";
                var keyge = "";
                // Split key and extract name and gender
                for (var x = 0; x < sp.length; x++) {
                  if (x == sp.length - 1) {
                    keyge = sp[x];
                  } else {
                    keyna = keyna + (x == 0 ? "" : " ") + sp[x];
                  }
                }

                if (productName.includes(keyna)) {
                  var ss = sex == "Men's" ? "m" : "w";
                  // Check if gender matches
                  if (ss == keyge) {
                    rideValue =
                      response.data.data["Shoe Model Recommendations"][key];
                  } else {
                  }
                }
                sizeshoe.push(key);
              }
            }

            // Set shoe size and all sizes
            setShoesize(rideValue);
            setallsizes(response.data.data["Shoe Model Recommendations"]);
            var allsizes = response.data.data["Shoe Model Recommendations"];

            // Handle based on sendtype
            if (sendtype == "computer") {
              const gueststatus = JSON.parse(localStorage.getItem("guest"));
              const useremail = JSON.parse(localStorage.getItem("useremail"));
              if (gueststatus) {
                setShoponcomputerpopup(true);
              } else {
                setIsLoading1(true);
                var collectionid = JSON.parse(
                  localStorage.getItem("collectionId")
                );
                console.log(collections);
                debugger;
                // Create body for sending email
                var bodie = {
                  email: useremail,
                  url:
                    collections === "products"
                      ? url +
                        "?product=" +
                        productId +
                        "&shoeSize=" +
                        rideValue +
                        "&category=" +
                        JSON.parse(gender) +
                        "&brand=" +
                        JSON.stringify(selectedBrands) +
                        "&allsizes=" +
                        JSON.stringify(allsizes) +
                        "&ordertype=" +
                        orderType
                      : collections === "collections"
                      ? url +
                        "?collectionid=" +
                        collectionid +
                        "&shoeSize=" +
                        rideValue +
                        "&category=" +
                        JSON.parse(gender) +
                        "&brand=" +
                        JSON.stringify(selectedBrands) +
                        "&allsizes=" +
                        JSON.stringify(allsizes) +
                        "&ordertype=" +
                        orderType
                      : collections === "index"
                      ? url +
                        "?collectionid=" +
                        collectionid +
                        "&shoeSize=" +
                        rideValue +
                        "&category=" +
                        JSON.parse(gender) +
                        "&brand=" +
                        JSON.stringify(selectedBrands) +
                        "&allsizes=" +
                        JSON.stringify(allsizes) +
                        "&ordertype=" +
                        orderType
                      : home,
                };
                const response = await axios.post(
                  process.env.REACT_APP_BASEURL + "/api/send_email",
                  bodie
                );

                if (response.data.message) {
                  setIsLoading1(false);
                  alert.show(response.data.message);
                  navigate("/");
                }
              }
            } else if (sendtype == "mobile") {
              var isios = iOS();
              if (collections == "products") {
                if (isios) {
                  window.location.assign(
                    url +
                      "?product=" +
                      productId +
                      "&shoeSize=" +
                      rideValue +
                      "&category=" +
                      JSON.parse(gender) +
                      "&brand=" +
                      JSON.parse(selectedBrands) +
                      "&allsizes=" +
                      JSON.stringify(allsizes) +
                      "&ordertype=" +
                      orderType,
                    "_blank"
                  );
                } else {
                  window.open(
                    url +
                      "?product=" +
                      productId +
                      "&shoeSize=" +
                      rideValue +
                      "&category=" +
                      JSON.parse(gender) +
                      "&brand=" +
                      JSON.parse(selectedBrands) +
                      "&allsizes=" +
                      JSON.stringify(allsizes) +
                      "&ordertype=" +
                      orderType,
                    "_blank"
                  );
                }
              } else if (collections == "collections") {
                var collectionid = JSON.parse(
                  localStorage.getItem("collectionId")
                );
                if (isios) {
                  window.location.assign(
                    url +
                      "?collectionid=" +
                      collectionid +
                      "&shoeSize=" +
                      rideValue +
                      "&category=" +
                      JSON.parse(gender) +
                      "&brand=" +
                      JSON.parse(selectedBrands) +
                      "&allsizes=" +
                      JSON.stringify(allsizes) +
                      "&ordertype=" +
                      orderType,
                    "_blank"
                  );
                } else {
                  window.open(
                    url +
                      "?collectionid=" +
                      collectionid +
                      "&shoeSize=" +
                      rideValue +
                      "&category=" +
                      JSON.parse(gender) +
                      "&brand=" +
                      JSON.parse(selectedBrands) +
                      "&allsizes=" +
                      JSON.stringify(allsizes) +
                      "&ordertype=" +
                      orderType,
                    "_blank"
                  );
                }
              } else {
                if (isios) {
                  window.location.assign(home, "_blank");
                } else {
                  window.open(home, "_blank");
                }
              }
            }
          } else {
            alert.show(response.data.message);
            return;
          }
        });
    } catch (error) {
      console.log("error");
    }
  };

  // Function to check if the current device is running iOS
  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  // Function to handle guest submission
  const guestsubmit = async () => {
    // Retrieve data from local storage
    const gender = JSON.parse(localStorage.getItem("gender"));
    const productId = localStorage.getItem("productId");
    const selectedBrands = JSON.parse(localStorage.getItem("selectedBrands"));
    setIsLoading(true);
    const data = { email };

    // Validate email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailPattern.test(email);
    setIsValid(isValidEmail);

    // Check if email is provided and valid
    if (!email) {
      alert.show("Please enter email id");
      setIsLoading(false);
      return;
    }
    if (!isValidEmail) {
      alert.show("Invalid email address");
      setIsLoading(false);
      return;
    }
    try {
      // Retrieve collection ID from local storage
      var collectionid = JSON.parse(localStorage.getItem("collectionId"));
      var orderType = localStorage.getItem("orderType");

      console.log(collections);
      // Construct the request body based on the selected collection
      var body = {
        email: email,
        url:
          collections === "products"
            ? url +
              "?product=" +
              productId +
              "&shoeSize=" +
              shoesize +
              "&category=" +
              gender +
              "&brand=" +
              JSON.stringify(selectedBrands) +
              "&allsizes=" +
              JSON.stringify(allsizes) +
              "&ordertype=" +
              orderType
            : collections === "collections"
            ? url +
              "?collectionid=" +
              collectionid +
              "&shoeSize=" +
              shoesize +
              "&category=" +
              gender +
              "&brand=" +
              JSON.stringify(selectedBrands) +
              "&allsizes=" +
              JSON.stringify(allsizes) +
              "&ordertype=" +
              orderType
            : collections === "index"
            ? url +
              "?collectionid=" +
              collectionid +
              "&shoeSize=" +
              shoesize +
              "&category=" +
              gender +
              "&brand=" +
              JSON.stringify(selectedBrands) +
              "&allsizes=" +
              JSON.stringify(allsizes) +
              "&ordertype=" +
              orderType
            : home,
      };
      // API call to send email
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/api/send_email",
        body
      );
      // Check if the response contains a message
      console.log("response", response);
      console.log("response.data.message", response.data.message);
      if (response.data.message) {
        setIsLoading(false);
        alert.show(response.data.message);
        navigate("/");
      }
    } catch (error) {
      // Handle and display any error that occurs during the API call
      alert.show(error.message);
    }
  };

  return (
    <>
      <div className="comp-popup">
        {isLoading1 ? (
          <Spinners />
        ) : (
          <div className="comp-content">
            <div className="comppop-main">
              <img
                className="compsuccesslogo"
                src={successlogo}
                alt="successlogo"
              />
              <div className="comppop-div">
                <h1 className="comppop-h1">Foot Scans Complete</h1>
              </div>
              <p className="comppop-p">
                Your shoe size for each shoe will be recommended to you as you
                continue to shop.
              </p>
              <button className="comppop-btn" onClick={mobilehandlebutton}>
                Shop On Mobile
              </button>
              <button className="comppop-btn1" onClick={computerhandlebutton}>
                Shop On Computer
              </button>
            </div>
          </div>
        )}
      </div>

      <div>
        {shoponcomputerpopup && (
          <div className="footcom-popup1">
            {isLoading ? (
              <Spinners />
            ) : (
              <div className="footcom-content1">
                <i
                  className="fal fa-times footcompop-i"
                  onClick={shoponcomputertoggle}
                ></i>
                <div className="footcom-content11">
                  <div className="footcom-logodiv">
                    <img className="pop2logo " src={pop2logo} alt="img1" />
                  </div>
                  <div className="footcom-pop1div">
                    <h1 className="footcom2-h1">Email</h1>
                    <p className="footcom1-p">
                      Enter your email to continue shopping on your computer.
                    </p>
                    <p className="footcom2-p1">
                      Click the link in the email to receive shoe size guidance
                      while you shop.
                    </p>

                    <div className="input-group mb-3 footcom-inputdiv">
                      <span className="input-group-text " id="basic-addon1">
                        <i className="fal fa-envelope"></i>
                      </span>
                      <input
                        type="email"
                        className="form-control footcom-input"
                        placeholder="Email"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <button className="footcom-btn" onClick={guestsubmit}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <ToastContainer />
      </div>
    </>
  );
}
