import React from "react";
import { useNavigate } from "react-router-dom";
import "./welcome.css";
import welcomeimg from "../asset/WlcomeImage.png";
import { useAlert } from "react-alert";

export default function Welcome() {
  const navigate = useNavigate();
  const alert = useAlert();
  // Function to display an alert when no product or collection is selected
  function selectscan() {
    alert.show("Please scan product first");
  }

  // Function to handle the arrow-right click event and navigate to the next page
  function handleclick() {
    // Retrieving product information from local storage
    const productName = localStorage.getItem("productName");
    const productId = localStorage.getItem("productId");

    const collectionId = localStorage.getItem("collectionId");

    // Checking if a product or collection is selected
    var orderType = localStorage.getItem("orderType")
    if (orderType == 'catalogue' || orderType == 'home' || orderType == 'product' || orderType == 'collection') {
    }else{
        return selectscan();
      }

    // Retrieving and logging user agent information
    const userAgent = navigator.userAgent;

    // Handling different device types
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      // Extracting iPhone OS version
      const iPhoneOSVersionMatch = userAgent.match(/iPhone OS (\S+)/);
      const iPhoneOSVersion = iPhoneOSVersionMatch
        ? iPhoneOSVersionMatch[1]
        : "Unknown";

      localStorage.setItem("device id", JSON.stringify(iPhoneOSVersion));
    } else if (/Android/i.test(userAgent)) {
      // Extracting Android device identifier
      const deviceNameMatch = userAgent.match(/\((.*?)\)/); //

      if (deviceNameMatch && deviceNameMatch.length > 1) {
        const deviceName = deviceNameMatch[1];
        const parts = deviceName.split(";");
        const deviceIdentifier = parts[2].trim();
        localStorage.setItem("device id", JSON.stringify(deviceIdentifier));
      }
    } else {
      console.log("Device: Other");
    }
    // Navigating to the next page
    navigate("/instructions");
  }

  return (
    <div className="wel-main">
      <div className="wel-fdiv">
        <img className="wel-logo" src={welcomeimg} alt="welcomeimg" />
      </div>

      <div className="wel-sdiv">
        <h1 className="wel-h1">Welcome To</h1>
        <h1 className="wel-h1">Shoe-SZR</h1>
        <p className="wel-p">
          The app that simplifies online shoe selection and shoe sizing
        </p>
      </div>
      <div className="wel-lastdiv">
        <div>
          <i className="fas fa-arrow-right wel-i" onClick={handleclick}></i>
        </div>
      </div>
    </div>
  );
}
