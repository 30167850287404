import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './Component/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ReactPWAInstallProvider enableLogging>
  <React.StrictMode>
  <AlertProvider template={AlertTemplate} {...options}>
    <App />
  </AlertProvider>
  </React.StrictMode>
  </ReactPWAInstallProvider>
);


if ('serviceWorker' in navigator) {
  serviceWorkerRegistration.register();
}
window.addEventListener("beforeinstallprompt", (e) => {

  e.userChoice.then((choiceResult) => {
  }, handleError);
});

reportWebVitals();
function handleError(error){
  console.log(error)
}