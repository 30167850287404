import React from "react";
import { useNavigate } from "react-router-dom";
import Firstgif from "../../asset/firstgif.gif";
import "./start.css";
import navimg from "../../asset/Screen 1 - ShoeSZR Logo.png";
import { useAlert } from "react-alert";

export default function Start() {
  const navigate = useNavigate();
  const alert = useAlert();

  // Function to display an alert when no product or collection is selected
  function selectscan() {
    alert.show("Please scan product first");
  }
  var orderType;
  // Function to handle Start now click event
  function handlestartnowclick() {  
    // Retrieving data from local storage
    const productName = localStorage.getItem("productName");
    const collectionId = localStorage.getItem("collectionId");

    const productId = localStorage.getItem("productId");

    // Checking if a product or collection is selected
    if (
      orderType == "catalogue" ||
      orderType == "home" ||
      orderType == "product" ||
      orderType == "collection"
    ) {
    } else {
      return selectscan();
    }
    navigate("/");
  }

  // Clearing specific items from local storage
  localStorage.removeItem("productId");
  localStorage.removeItem("collectionId");
  localStorage.removeItem("storename");
  localStorage.removeItem("scanurl");
  localStorage.removeItem("orderType");

  // Retrieving URL parameters
  const urlParams = new URLSearchParams(window.location.search);
  const product_id = urlParams.get("product_id") ?? "";
  const product_name = urlParams.get("product_name") ?? "";
  const collection_id = urlParams.get("collection_id") ?? "";

  // Storing product information in local storage
  if (product_id) {
    localStorage.setItem("orderType", "product");
    localStorage.setItem("productId", product_id);
  }
  if (product_name) {
    localStorage.setItem("productName", product_name);
  }
  if (collection_id) {
    localStorage.setItem("orderType", "collection");
    localStorage.setItem("collectionId", collection_id);
  }

  // Retrieving and processing URL information
  var url = urlParams.get("url") ?? "";
  var urls = url.split("/");
  if (url.includes("collections/all")) {
    localStorage.setItem("orderType", "catalogue");
  }
  if(urls.length>3){
    localStorage.setItem("orderType", "home");
  }
  orderType = localStorage.getItem("orderType");
  if (url.includes("collections")) {
    localStorage.setItem("storename", urls[urls.length - 2]);
  } else if (url.includes("products")) {
    localStorage.setItem("storename", urls[urls.length - 2]);
  } else {
    console.log(urls[urls.length - 2])
    // const urlParams = new URLSearchParams(new URL(url).search);
    // const extractedUrl = urlParams.get("url");
    // localStorage.setItem("home", extractedUrl);
    // console.log(new URL(url).search)
    // console.log(urlParams)
    // console.log(extractedUrl)
    // const parsedUrl = new URL(extractedUrl);
    // const domain = parsedUrl.hostname;
    localStorage.setItem("storename", urls[urls.length - 2]);
  }
  if (url) {
    var surls = url.split("?");
    localStorage.setItem("scanurl", surls[0]);
  }

  return (
    <div className="start-popup">
      <div className="start-content">
        <nav className="start-navbar1">
          <div className="start-navdiv">
            <img className="start_navlogo1" src={navimg} alt="navlogo" />
            <p className="nav1-p1">ShoeSZR</p>
          </div>
        </nav>
        <div className="startimgdiv">
          <img src={Firstgif} alt="Firstgif" />
        </div>
        <button className="start-btn" onClick={handlestartnowclick}>
          Start now
        </button>
      </div>
    </div>
  );
}
