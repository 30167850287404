import React from "react";
import navimg from "../../asset/Screen 1 - ShoeSZR Logo.png";
import img1 from "../../asset/Screen 24 - icon1 2.png";
import img3 from "../../asset/Screen 24 - icon3White 1.png";
import "./shoeshopping.css";

export default function Shoeshopping() {
 

  return (
    <div className="shoeshopp-popup">
      <div className="shoeshopp-content">
        <div className="shoeshopp-idiv">
          <i className="fa-sharp fa-regular fa-circle-xmark shoeshopp-i"></i>
        </div>
        <nav className="shoeshopp-navbar1">
          <div className="shoeshopp-navdiv">
            <img className="shoeshopp_navlogo1" src={navimg} alt="navlogo" />
            <p className="nav1-p1">ShoeSZR</p>
          </div>
        </nav>
        <h1 className="shoeshopp-h1">The recipe to finding your true size</h1>

        <div className="shoeshopp-div1">
          <div className="shoeshopp-imgdiv">
            <img className="shoeshopp-img1" src={img1} alt="img1" />
          </div>
          <div className="shoeshopp-container1">
            <h1 className="shoeshopp-p1">YOU find your unique features</h1>
            <p className="shoeshopp-p2">WE take measurements of your feet</p>
          </div>
        </div>

        <div className="shoeshopp-div1">
          <div className="shoeshopp-imgdiv1">
            <img className="shoeshopp-img1" src={img3} alt="img3" />
          </div>
          <div className="shoeshopp-container1">
            <p className="shoeshopp-p1">YOU browse for different shoes</p>
            <p className="shoeshopp-p2">
              WE have taken measurements of every shoe
            </p>
          </div>
        </div>

        <div className="shoeshopp-div1 -last">
          <div className="shoeshopp-imgdiv1">
            <img className="shoeshopp-img1" src={img3} alt="img3" />
          </div>
          <div className="shoeshopp-container1">
            <p className="shoeshopp-p1">YOU are told your size in every shoe</p>
            <p className="shoeshopp-p2">
              WE virtually try on the shoes for you
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
