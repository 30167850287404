import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import locklogo from "../../asset/majesticons_user-box-line.png";
import passwordlogo from "../../asset/solar_password-outline.png";
import axios from "axios";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import snake from "../../asset/output-onlinegiftools.gif";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [cusalert, setAlert] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Function to show alert messages
  const showAlert = (type, message) => {
    setAlert({ type, message });
  };

  // Function to close alert
  const closeAlert1 = () => {
    setAlert(null);
  };

  // useEffect hook to run code after the component mounts
  useEffect(() => {
    getCookieData();
  }, []);

  // Function to handle back button click
  function backbuttonclick() {
    navigate("/signin");
  }

  // Function to handle login button click
  function handleclick() {
    try {
      const data = { email, password, isChecked };
      // Validate email format
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailPattern.test(email);
      setIsValid(isValidEmail);

      // Check for empty email or password
      if (!email || !password) {
        showAlert("error", "Please enter email and password");
        return;
      } else if (!isValid) {
        showAlert("error", "Invalid email address");
        return;
      }
      setIsLoading(true);

      // API call to login
      var body = {
        email: email,
        password: password,
      };
      axios
        .post(process.env.REACT_APP_BASEURL+`/api/login`, body)
        .then((response) => {
          // Handle login success
          if (
            response.data &&
            response.data.status &&
            response.data.message &&
            response.data.data &&
            response.data.data.user
          ) {
            showAlert("error", response.data.message);
            // Save user data to local storage
            localStorage.setItem(
              "useremail",
              JSON.stringify(response.data.data.user.email)
            );
            // Save user data to cookies if the checkbox is checked
            if (checkCheckbox()) {
              Cookies.set("email", email);
              Cookies.set("password", password);
            }
            localStorage.setItem(
              "userData",
              JSON.stringify(response.data.data.user._id)
            );
            localStorage.setItem("guest", JSON.stringify(false));

            localStorage.removeItem("device id");
            setIsLoading(false);
            navigate("/shoeselect");
          } else {
            showAlert("error", "Login failed.");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          // Show error alert
          console.log(error.response.data);
          showAlert("error", error.response.data.message);
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  // Function to check if the "Remember" checkbox is checked
  function checkCheckbox() {
    try {
      var checkboxe = document.querySelectorAll("input[type=checkbox]:checked");
      if (checkboxe.length != 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Function to get data from cookies
  function getCookieData() {
    var emaill = Cookies.get("email");
    var passwordd = Cookies.get("password");
    if (emaill != undefined) {
      setEmail(emaill);
    }
    if (passwordd != undefined) {
      setPassword(passwordd);
    }
  }

  return (
    <>
      <div className="loginmain-section">
        <div className="loginbackground_img_sec">
          <i
            className="fas fa-arrow-left shoesel-i"
            onClick={backbuttonclick}
          ></i>
          <h1 className="login-h1">Log In To Your Account</h1>
          <img className="locklogo" src={locklogo} alt="locklogo" />
        </div>

       {/* Login form section */}
        <div className="login-div">
           {/* Email input */}
          <div className="input-group mb-3">
            <span className="input-group-text " id="basic-addon1">
              <i className="fal fa-envelope"></i>
            </span>
            <input
              type="email"
              className="form-control login-input"
              placeholder="Email"
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          {/* Password input */}
          <div className="input-group mb-3">
            <span className="input-group-text " id="basic-addon1">
              {" "}
              <img className="" src={passwordlogo} alt="passwordlogo" />
            </span>
            <input
              type="password"
              className="form-control login-input1"
              placeholder="Password"
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
            {/* Remember checkbox and Forgot Password link */}
          <div className="login-div1">
            <div>
              <label className="center-label login-label">
                <input
                  type="checkbox"
                  className="login-input2"
                  onChange={checkCheckbox}
                />
                Remember
              </label>
            </div>
            <div>
              <a className="login-for" href="/forgotpassword">
                Forgot Password?
              </a>
            </div>
          </div>
            {/* Login button */}
          <button className="login-btn" onClick={handleclick}>
            Log In
          </button>
        </div>
          {/* Sign up link */}
        <p className="login-lastp">
          Don't have an account?{" "}
          <a href="/signup" className="login-lasta">
            Sign Up
          </a>
        </p>
      </div>

      {/* Loading spinner */}
      {isLoading && (
        <div className="custom-alert1">
          <img src={snake} className="snakeloader" alt="navlogo" />
        </div>
      )}

       {/* Alert messages */}
      <div className="custom-App">
        {cusalert && cusalert.message && (
          <div className={`custom-alert ${cusalert.type}`}>
            <div>{cusalert.message}</div>
            <hr className="custom-hr" />
            <button className="custom-btn" onClick={closeAlert1}>
              OK
            </button>
          </div>
        )}
      </div>
    </>
  );
}
