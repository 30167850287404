import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./shoeselect.css";
import Nikeimage from "../../asset/1-Nike .png";
import Adidasimage from "../../asset/2-Adidas.png";
import Pumaimage from "../../asset/3-Puma.png";
import Asicsimage from "../../asset/BrandIcons/Property 1=4-Asics.png";
import Reebokimage from "../../asset/BrandIcons/Property 1=5_Reebok.png";
import NewBaimage from "../../asset/BrandIcons/Property 1=6_NewBalance.png";
import Converseimage from "../../asset/BrandIcons/Property 1=7_Converse.png";
import Skechersimage from "../../asset/BrandIcons/Property 1=8_Skechers.png";
import Sauconyimage from "../../asset/BrandIcons/Property 1=9_Saucony.png";
import UnderArimage from "../../asset/BrandIcons/Property 1=10_UnderArmour.png";
import Hokaimage from "../../asset/BrandIcons/Property 1=11_Hoka.png";
import Brooksimage from "../../asset/BrandIcons/Property 1=12_Brooks.png";
import morelogo from "../../asset/gg_more-o.svg";
import malelogo from "../../asset/fontisto_male.svg";
import femalelogo from "../../asset/fontisto_famale.svg";

export default function Shoeselect() {
  const [moreshoespopup, setMoreshoespopup] = useState(false);
  const [selectedGender, setSelectedGender] = useState("");
  const navigate = useNavigate();
  const navigate1 = useNavigate();
  const [cusalert, setAlert] = useState(null);

  // Function to show alerts
  const showAlert = (type, message) => {
    setAlert({ type, message });
  };

  // Function to close alerts
  const closeAlert1 = () => {
    setAlert(null);
  };

  // Array of initial shoe items
  let initialShoeItems = [
    { id: 1, logo: Nikeimage, brand: "Nike" },
    { id: 2, logo: Adidasimage, brand: "Adidas" },
    { id: 3, logo: Pumaimage, brand: "Puma" },
    { id: 4, logo: Asicsimage, brand: "Asics" },
    { id: 5, logo: Reebokimage, brand: "Reebok" },
    { id: 6, logo: NewBaimage, brand: "New Ba..." },
    { id: 7, logo: Converseimage, brand: "Converse" },
    { id: 8, logo: Skechersimage, brand: "Skechers" },
    { id: 9, logo: Sauconyimage, brand: "Saucony" },
    { id: 10, logo: UnderArimage, brand: "Under Ar..." },
    { id: 11, logo: Hokaimage, brand: "Hoka" },
    { id: 12, logo: Brooksimage, brand: "Brooks" },
  ];

  // State variables for shoe items and selected brands
  const [shoeItems, setShoeItems] = useState(initialShoeItems);
  const [selectedBrands, setSelectedBrands] = useState([]);

  // Function to handle clicking on a shoe item
  const handleShoeItemClick = (item) => {
    const updatedShoeItems = shoeItems.map((shoeItem) =>
      shoeItem.id === item.id
        ? { ...shoeItem, selected: !shoeItem.selected }
        : shoeItem
    );
    setShoeItems(updatedShoeItems);

    if (!item.selected) {
      setSelectedBrands([...selectedBrands, item.brand]);
    } else {
      setSelectedBrands(selectedBrands.filter((brand) => brand !== item.brand));
    }
  };
  localStorage.setItem("selectedBrands", JSON.stringify(selectedBrands));

  // Function to handle gender click
  const handlegenderclick = (item1) => {
    setSelectedGender(item1);
    localStorage.setItem("gender", JSON.stringify(item1));
  };

  // Function to navigate to the signin page
  function handlebackclick() {
    navigate("/signin");
  }

  // Function to handle the "Next" button click
  function handlenextbutton(event) {
    event.preventDefault();
    const selectedShoeItems = shoeItems.filter((item) => item.selected);

    if (selectedShoeItems.length === 0 && !selectedGender) {
      showAlert(
        "error",
        "Please select at least one brand and only one shoe category."
      );
    } else if (selectedShoeItems.length === 0) {
      showAlert("error", "Please select at least one shoe brand.");
    } else if (!selectedGender) {
      showAlert("error", "Please select a shoe category.");
    } else {
      navigate1("/startscan");
    }
  }

  // Function to toggle the brand popup
  const moreicontoggle = () => {
    setMoreshoespopup(!moreshoespopup);
  };

  return (
    <>
      <div className="shoe-main">
        <i className="fas fa-arrow-left shoesel-i" onClick={handlebackclick}></i>
        <h1 className="shoe-h1">What are you looking for?</h1>
        <p className="shoe-p">
          Select which brands you buy (select at least one)
        </p>
        <div className="shoe-logomain">
          {shoeItems.map((item, ind) =>
            ind < 7 ? (
              <div
                className={`shoe-item ${item.selected ? "selected" : ""}`}
                key={item.id}
                onClick={() => handleShoeItemClick(item)}
              >
                <div className="shoe-arrimg">
                  <img
                    className="shoe-logo"
                    src={item.logo}
                    alt={`logo${item.id}`}
                  />
                </div>
                <p className={`shoe-p${item.id}`}>{item.brand}</p>
              </div>
            ) : null
          )}
          <div className="shoe-item">
            <div className="shoe-arrimg">
              <img
                className="shoe-logomore"
                src={morelogo}
                alt="morelogo"
                onClick={moreicontoggle}
              />
            </div>
            <p className="shoe-pmore" onClick={moreicontoggle}>
              More
            </p>
          </div>
        </div>
        <p className="shoe-lastp">Select your shoe category</p>
        <div className="shoe-logomain2">
          <div
            className={`shoe-item2 ${
              selectedGender === "Men's" ? "selected" : ""
            }`}
            onClick={() => handlegenderclick("Men's")}
          >
            <img className="shoe-male" src={malelogo} alt="malelogo" />
            <p>Male</p>
          </div>
          <div
            className={`shoe-item2 ${
              selectedGender === "Women's" ? "selected" : ""
            }`}
            onClick={() => handlegenderclick("Women's")}
          >
            <img className="shoe-female" src={femalelogo} alt="femalelogo" />
            <p className="shoe-fe">Female</p>
          </div>
        </div>
        <button className="shoe-btn" onClick={handlenextbutton}>
          Next
        </button>
      </div>

      {moreshoespopup && (
        <div className="shoe-popup">
          <div className="shoe-content">
            <p className="shoe-p">
              Select which brands you buy (select at least one)
            </p>
            <div className="shoe-logomain">
              {shoeItems.map((item) => (
                <div
                  className={`shoe-item ${item.selected ? "selected" : ""}`}
                  key={item.id}
                  onClick={() => handleShoeItemClick(item)}
                >
                  <div className="shoe-arrimg">
                    <img
                      className="shoe-logo"
                      src={item.logo}
                      alt={`logo${item.id}`}
                    />
                  </div>
                  <p className={`shoe-p${item.id}`}>{item.brand}</p>
                </div>
              ))}
            </div>
            <button className="popshoe-btn" onClick={moreicontoggle}>
              Next
            </button>
          </div>
        </div>
      )}

      <div className="custom-App">
        {cusalert && cusalert.message && (
          <div className={`custom-alert ${cusalert.type}`}>
            <div>{cusalert.message}</div>
            <hr className="custom-hr" />
            <button className="custom-btn" onClick={closeAlert1}>
              OK
            </button>
          </div>
        )}
      </div>
    </>
  );
}
