import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./forgot.css";
import forgotlogo from "../../asset/pngegg.png";
import snake from "../../asset/output-onlinegiftools.gif";

export default function Forgotpassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [cusalert, setAlert] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Function to show alert messages
  const showAlert = (type, message) => {
    setAlert({ type, message });
  };

  // Function to close alert
  const closeAlert1 = () => {
    setAlert(null);
  };

  // Function to handle Send Password Reset Link button click
  function Resetlinkclick() {
    // Validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailPattern.test(email);
    setIsValid(isValidEmail);

    // Check for empty or invalid email
    if (!email) {
      showAlert("error", "Please enter email id");
      return;
    } else if (!isValid) {
      showAlert("error", "Invalid email address");
      return;
    }
    setIsLoading(true);
    // API call to request reset password
    var body = {
      email: email,
    };
    axios
      .post(process.env.REACT_APP_BASEURL+"/api/forgot-password", body)
      .then((response) => {
        if (response.data && response.data.message) {
          if (response.data.status === true) {
            // Show success alert and navigate to resetpassword page
            showAlert("success", response.data.message);
            setIsLoading(false);
            navigate("/resetpassword");
          } else {
            // Show error alert
            showAlert("error", response.data.message);
            setIsLoading(false);
          }
        } else {
          // Show error alert for invalid response
          showAlert("error", "Invalid response received from the server");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }
  return (
    <>
      <div className="forgotmain-section">
        <div className="forgotbackground_img_sec">
          <h1 className="forgot-h1">Forgot Your Password?</h1>
          <img className="forgotlogo" src={forgotlogo} alt="forgotlogo" />
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="forgotform_section">
              <div className="forgot-div">
                <p className="forgot-p">
                  Please enter your email address to reset your password
                </p>
                <div className="input-group mb-3">
                  <span className="input-group-text " id="basic-addon1">
                    <i className="fal fa-envelope"></i>
                  </span>
                  <input
                    type="email"
                    className="form-control forgot-input"
                    placeholder="Email"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <button className="forgot-btn" onClick={Resetlinkclick}>
                  Send Password Reset Link
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Loading spinner */}
      {isLoading && (
        <div className="custom-alert1">
          <img src={snake} className="snakeloader" alt="navlogo" />
        </div>
      )}

      {/* Alert messages */}
      <div className="custom-App">
        {cusalert && cusalert.message && (
          <div className={`custom-alert ${cusalert.type}`}>
            <div>{cusalert.message}</div>
            <hr className="custom-hr" />
            <button className="custom-btn" onClick={closeAlert1}>
              OK
            </button>
          </div>
        )}
      </div>
    </>
  );
}
