import React from "react";
import { useNavigate } from "react-router-dom";
import "./startscan.css";
import startscanlogo from "../../asset/OverView_Image1 (1).png";

export default function Startscan() {
  const navigate = useNavigate();

  // Function to navigate to the "/footvideo" route
  function stepbystep() {
    navigate("/footvideo");
  }

  // Function to navigate to the "/scaninstructions" route
  function textsummary() {
    navigate("/scaninstructions");
  }

  // Function to navigate to the "/shoeselect" route
  function handlebackclick() {
    navigate("/shoeselect");
  }

  return (
    <div className="startscan-main">
      <i className="fas fa-arrow-left shoesel-i" onClick={handlebackclick}></i>
      <h1 className="startscan-h1">Start scanning to find your size</h1>
      <img className="startscan-logo" src={startscanlogo} alt="startscanlogo" />
      <p className="startscan-p">Select your preferred instructions</p>

      <button className="startscan-btn" onClick={stepbystep}>
        Step-by-Step Video
      </button>
      <button className="startscan-btn1" onClick={textsummary}>
        Text Summary
      </button>
    </div>
  );
}
