import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./startscanning.css";
import scanninglogo from "../../asset/Letter - 8 1.png";

export default function Startscanning() {
  const navigate = useNavigate();
  const navigate1 = useNavigate();
  const [allowpopup, setAllowpopup] = useState(false);
  const [cusalert, setAlert] = useState(null);

  // Function to display custom alerts
  const showAlert = (type, message) => {
    setAlert({ type, message });
  };

  // Function to close custom alerts
  const closeAlert1 = () => {
    setAlert(null);
  };

  // Initial paper size items
  const [sizeItems, setSizeItems] = useState([
    { id: 1, logo: scanninglogo, size: "Letter - 8.5x11", selected: false },
    { id: 2, logo: scanninglogo, size: "Legal - 8.5x14", selected: false },
  ]);

  const [selectedId, setSelectedId] = useState(null);

  // Function to handle click on a paper size item
  const handleSizeItemClick = (item) => {
    // Update the state to mark the selected paper size
    const updatedSizeItems = sizeItems.map((prevItem) =>
      prevItem.id === item.id
        ? { ...prevItem, selected: true }
        : { ...prevItem, selected: false }
    );

    setSizeItems(updatedSizeItems);
    setSelectedId(item.id);
    localStorage.setItem("size", JSON.stringify(item.size));
  };

  //function handle to Start Scanning button
  const handlestartscanning = () => {
    // Check if a paper size is selected before opening the popup
    if (selectedId !== null) {
      setAllowpopup(!allowpopup);
    } else {
      showAlert(
        "error",
        "Please select a paper size before starting scanning."
      );
    }
  };

  // Function to navigate to the Leftfoot page
  function handleallowclick() {
    navigate1("/leftfoot");
  }

  // Function to navigate to the scanning instructions page
  function handlebackclick() {
    navigate("/scaninstructions");
  }

  return (
    <>
      <div className="startscaning-main">
        <i className="fas fa-arrow-left shoesel-i" onClick={handlebackclick}></i>
        <h1 className="startscaning-h1">Paper Size Selection</h1>
        <p className="starscanning-p">
          Select what paper size you will be using
        </p>

        <div className="startscaning-logomain">
          {sizeItems.map((item) => (
            <div key={item.id}>
              <div
                className={`startscaning-item ${
                  item.selected ? "selected" : ""
                }`}
                onClick={() => handleSizeItemClick(item)}
              >
                <img
                  className={`startscaning-logo1 startscaning-logo1${item.id}`}
                  src={item.logo}
                  alt={`logo${item.id}`}
                />
              </div>
              <p className={`startscaning-p${item.id}`}>{item.size}</p>
            </div>
          ))}
        </div>

        <button className="startscaning-btn" onClick={handlestartscanning}>
          Start Scanning
        </button>
      </div>
      {allowpopup && (
        <div className="startscaning-popup">
          <div className="startscaning-content1">
            <div className="pop-text">
              <span className="pop-textspan1">“</span>
              <span className="pop-textspan2">ShoeSZR</span>
              <span className="pop-textspan3">” Would Like to</span>
              <span className="pop-textspan4"> Access the Camera</span>
            </div>
            <div className="pop-button">
              <button className="pop-btn1" onClick={handlestartscanning}>
                Cancel
              </button>
              <button className="pop-btn2" onClick={handleallowclick}>
                Allow
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="custom-App">
        {cusalert && cusalert.message && (
          <div className={`custom-alert ${cusalert.type}`}>
            <div>{cusalert.message}</div>
            <hr className="custom-hr" />
            <button className="custom-btn" onClick={closeAlert1}>
              OK
            </button>
          </div>
        )}
      </div>
    </>
  );
}
